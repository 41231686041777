import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/BankDetails.css';
import axios from 'axios'; // Ensure axios is imported

const BankDetails = () => {
  const [name, setName] = useState('');
  const [account, setAccount] = useState('');
  const [newName, setNewName] = useState('');
  const [newAccount, setNewAccount] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [upiId, setUpiId] = useState('');
  const [newIfscCode, setNewIfscCode] = useState('');
  const [newUpiId, setNewUpiId] = useState('');
  const [confirmUpiId, setConfirmUpiId] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');

  useEffect(() => {
    // Fetch details on component mount
    const mobileNumber = getCookie('user');
    if (mobileNumber) {
      fetchDetails(mobileNumber);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!newName || !newUpiId || !confirmUpiId || !newAccount || !confirmAccountNumber || !newIfscCode) {
      toast.error('All fields are required');
      return;
    }

    if (newUpiId !== confirmUpiId) {
      toast.error('UPI ID and Confirm UPI ID do not match');
      return;
    }

    if (newAccount !== confirmAccountNumber) {
      toast.error('Account Number and Confirm Account Number do not match');
      return;
    }

    // Fetch mobile number from localStorage
    const mobileNumber = getCookie('user');
    if (!mobileNumber) {
      toast.error('Mobile number not found');
      return;
    }

    // Prepare data for API call
    const payload = {
      mobileNumber,
      accountName: newName,
      accountNo: newAccount,
      upiId: newUpiId,
      ifscCode: newIfscCode
    };

    // Call API
    try {
      const response = await fetch('https://api.successfamily.live/api4/updatebankdetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (result.status === 1) {
        toast.success('Bank Details Updated Successfully');
      } else {
        toast.error(result.message || 'Failed to update bank details');
      }
    } catch (error) {
      toast.error('An error occurred while updating bank details');
    }
  };

  const fetchDetails = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api4/userbankdetails', {
        mobileNumber
      });
      const { name, accountno, ifsccode, phonepay } = response.data;
      setName(name);
      setNewName(name);
      setAccount(accountno);
      setNewAccount(accountno);
      setIfscCode(ifsccode);
      setNewIfscCode(ifsccode);
      setUpiId(phonepay);
      setNewUpiId(phonepay);
      setConfirmUpiId(phonepay);
      setConfirmAccountNumber(accountno);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setName('');
        setNewName('');
        setNewAccount('');
        setNewIfscCode('');
        setNewUpiId('');
        setConfirmUpiId('');
        setConfirmAccountNumber('');
      } else {
        fetchDetails(mobileNumber);
      }
    }
  };
  const getCookie = (name) => {
    const nameEQ = name + '=';
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let c = cookiesArray[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  return (
    <div className="bank-details-container">
      <ToastContainer />
      <h2>Enter your details carefully</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="accountHolderName">Account holder name</label>
          <input
            type="text"
            id="accountHolderName"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Account holder name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="upiId">Upi ID</label>
          <input
            type="text"
            id="upiId"
            value={newUpiId}
            onChange={(e) => setNewUpiId(e.target.value)}
            placeholder="UPI number"
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmUpiId">Confirm Upi ID</label>
          <input
            type="text"
            id="confirmUpiId"
            value={confirmUpiId}
            onChange={(e) => setConfirmUpiId(e.target.value)}
            placeholder="Confirm UPI number"
          />
        </div>
        <div className="form-group">
          <label htmlFor="accountNumber">Account number</label>
          <input
            type="text"
            id="accountNumber"
            value={newAccount}
            onChange={(e) => setNewAccount(e.target.value)}
            placeholder="Account number"
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmAccountNumber">Confirm account number</label>
          <input
            type="text"
            id="confirmAccountNumber"
            value={confirmAccountNumber}
            onChange={(e) => setConfirmAccountNumber(e.target.value)}
            placeholder="Confirm account number"
          />
        </div>
        <div className="form-group">
          <label htmlFor="ifscCode">IFSC code</label>
          <input
            type="text"
            id="ifscCode"
            value={newIfscCode}
            onChange={(e) => setNewIfscCode(e.target.value)}
            placeholder="IFSC code"
          />
        </div>
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default BankDetails;
