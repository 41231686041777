import React, { useEffect } from 'react';
import axios from 'axios';

const DynamicIcons = () => {
  const fetchIcons = async () => {
    try {
      // Fetch the logo data from your API
      const response = await axios.post('https://api.successfamily.live/api4/pagecontent', {
        pageName: 'Logo',
      });
      
      if (response.data && response.data.Content) {
        const iconBaseUrl = response.data.Content; // Assuming this is the base URL for icons
        // Update the favicon
        const faviconLink = document.querySelector("link[rel*='icon']");
        if (faviconLink) {
          faviconLink.href = `${iconBaseUrl}/favicon.png`;
        }

        // Update other icons dynamically (e.g., 192x192 and 512x512)
        const icon192 = document.querySelector("link[rel='apple-touch-icon'][sizes='192x192']");
        if (icon192) {
          icon192.href = `${iconBaseUrl}/logo192.png`;
        }

        const icon512 = document.querySelector("link[rel='apple-touch-icon'][sizes='512x512']");
        if (icon512) {
          icon512.href = `${iconBaseUrl}/logo512.png`;
        }
      } else {
        console.log('No icon data found');
      }
    } catch (error) {
      console.log('Error fetching icon data:', error);
    }
  };

  useEffect(() => {
    fetchIcons();
  }, []);

  return null; // This component doesn't render anything, it just updates icons
};

export default DynamicIcons;
