import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/Login.css';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [timer, setTimer] = useState(60); // Countdown starts from 60 seconds
  const [showResend, setShowResend] = useState(false); // To control the display of the Resend button
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false); // State to manage OTP sent status
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [logo, setLogo] = useState('');

  // Check if user is already logged in
  useEffect(() => {
    const loggedInUser = getCookie('user');
    if (loggedInUser) {
      navigate('/main', { state: { mobileNumber: loggedInUser } });
    }
  }, [navigate, id]);

  useEffect(() => {
    let countdown;

    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setShowResend(true); // Show the "Resend" button after 60 seconds
      setLoading(false); // Stop loading state when timer completes
      clearInterval(countdown);
    }

    return () => clearInterval(countdown);
  }, [timer, otpSent]);

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  // Login function with password
  const handleLogin = async () => {
    if (!mobileNumber) {
      toast.error('Please Enter Mobile Number');
      return;
    }
    if (!password) {
      toast.error('Please Enter Password');
      return;
    }
    try {
      const response = await axios.post('https://api.successfamily.live/api4/login', {
        mobileNumber,
        password,
      });
      const { message, status } = response.data;
      if (status === 1) {
        toast.success('Login successful', { position: 'top-center' });
        setCookie('user', mobileNumber, 10); // Store for 10 days
        navigate('/main', { state: { mobileNumber } });
      } else {
        toast.warning(message, { position: 'top-center' });
      }
    } catch (error) {
      toast.warning('Login failed:' + (error.response?.data?.message || error.message), {
        position: 'top-center',
      });
    }
  };

  // OTP login function
  const handleLogin2 = async () => {
    if (!mobileNumber) {
      toast.error('Please Enter Mobile Number');
      return;
    }
    if (!otp) {
      toast.error('Please Enter OTP');
      return;
    }

    try {
      const response = await axios.post('https://api.successfamily.live/api4/login2', {
        mobileNumber,
        otp,
      });

      const { message, status, sessionToken } = response.data;

      if (status === 1) {
        toast.success('Login successful', { position: 'top-center' });

        const sessionExpiryTime = new Date().getTime() + 48 * 60 * 60 * 1000;

        setCookie('user', mobileNumber, 10);
        setCookie('sessionToken', sessionToken, 10);
        setCookie('sessionExpiry', sessionExpiryTime.toString(), 10);

        navigate('/main', { state: { mobileNumber } });
      } else {
        toast.warning(message, { position: 'top-center' });
      }
    } catch (error) {
      toast.warning('Login failed: ' + (error.response?.data?.message || error.message), {
        position: 'top-center',
      });
    }
  };

  // OTP login with sponsor ID
  const handleLogin3 = async () => {
    if (!mobileNumber) {
      toast.error('Please Enter Mobile Number');
      return;
    }
    if (!otp) {
      toast.error('Please Enter OTP');
      return;
    }

    try {
      const response = await axios.post('https://api.successfamily.live/api4/login3', {
        mobileNumber,
        sponserid: id,
        otp,
      });

      const { message, status, sessionToken } = response.data;

      if (status === 1) {
        toast.success('Login successful', { position: 'top-center' });

        const sessionExpiryTime = new Date().getTime() + 240 * 60 * 60 * 1000;

        setCookie('user', mobileNumber, 10);
        setCookie('sessionToken', sessionToken, 10);
        setCookie('sessionExpiry', sessionExpiryTime.toString(), 10);

        setTimeout(() => {
          navigate('/main', { state: { mobileNumber } });
        }, 100);

      } else {
        toast.warning(message, { position: 'top-center' });
      }
    } catch (error) {
      toast.warning('Login failed: ' + (error.response?.data?.message || error.message), {
        position: 'top-center',
      });
    }
  };

  // Send OTP function
  const handleOtp = async () => {
    if (!mobileNumber) {
      toast.error('Please Enter Mobile Number');
      return;
    }
    setLoading(true);
    setShowResend(false);

    try {
      const response = await axios.post('https://api.successfamily.live/api4/sendotplogin', {
        mobileNumber,
      });

      if (response.status === 200) {
        toast.success('OTP sent successfully!', { position: 'top-center' });
        setOtpSent(true);
        setTimer(60);
        setLoading(false);
      } else {
        toast.warning(response.data, { position: 'top-center' });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data || 'Failed to send OTP. Please try again later.', {
        position: 'top-center',
      });
    }
  };

  const handleResendOTP = () => {
    setTimer(60);
    setShowResend(false);
    setOtpSent(true);
    handleOtp();
  };

  // Set cookie function
  const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  // Get cookie function
  const getCookie = (name) => {
    const nameEQ = name + '=';
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let c = cookiesArray[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

     // Function to fetch content from the API
     const fetchContent = async (pageName) => {
      try {
        const response = await axios.post('https://api.successfamily.live/api4/pagecontent', {
          pageName,
        });
  
        if (response.data && response.data.Content) {
          const fetchedContent = response.data.Content;
          setLogo(fetchedContent); // Set the logo URL
        } else {
          console.log('No content found in the response');
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log('Content not found');
        } else {
          console.log('Failed to call API:', error.message);
        }
      }
    };
  
  
    useEffect(() => {
      fetchContent('Logo');
    }, []); // Empty array ensures this runs only once after the initial render
  
     // Construct the logo URL dynamically
     const logoUrl = `${logo}logo.png`;

  return (
    <div className="auth-container">
      <header className="header theme-colour">
        <img src={logoUrl} alt="Logo" className="logo2" />
        <button className="auth-button" style={{display: 'none'}} onClick={handleSignUpClick}>Register</button>
      </header>
      <div className="auth-form">
        <h2 className="auth-header">Sign in</h2>
        <div className="input-container">
          <span>+91</span>
          <input
            type="text"
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            placeholder="Mobile number"
          />
        </div>
        <div className="input-container" style={{display: 'none'}}>
          <input
            type="password" // Use type="password" for the password input field
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
          />
        </div>
        {otpSent && ( // Show OTP input only if OTP has been sent
        <>
          <div className="input-container">
            <input
              type="text"
              value={otp}
              onChange={handleOtpChange}
              placeholder="OTP"
            />
          </div>
          <div className="input-container" style={{display: 'flex', justifyContent: 'flex-end'}}>
            {showResend ? (
              <a className="resendOtpBtn" onClick={handleResendOTP} style={{ marginLeft: '10px', cursor: 'pointer' }}>
                Resend OTP
              </a>
            ) : (
              <div style={{ fontWeight: '600' }}>Wait {timer} seconds before you can resend OTP.</div>
            )}
          </div>
          </>
        )}
        {otpSent ? (
          <button className="otp-button" onClick={handleLogin3}>Login</button>
        ) : (
          <button className="otp-button" onClick={handleOtp} disabled={loading}>
            {loading ? 'Processing...' : 'Send OTP'}
          </button>
        )}
        <ToastContainer />
        <p className="footer-text">
          By continuing I agree that MB SMART WEB PRIVATE LIMITED may store and process my data in accordance with the 
          About Contact Privacy Policy and that I am 18 years or older. I am not playing from Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
        </p>
      </div>
    </div>
  );
};

export default Login;
