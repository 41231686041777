import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from '../styles/Modal.module.css';

const BetModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  betData, 
  color, 
  multipliersamount, 
  betType, 
  gametime, 
  betid 
}) => {
  const [quantity, setQuantity] = useState(1);
  const [activeBalance, setActiveBalance] = useState(1);
  const [activeMultiplier, setActiveMultiplier] = useState('X1');
  const [isChecked, setIsChecked] = useState(true); // Checkbox is initially checked
  const [multipliers, setMultipliers] = useState(['X1', 'X5', 'X10', 'X20', 'X50', 'X100']); // Default multipliers
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null);
  const [betChoiceNumber, setBetChoiceNumber] = useState('');
  const [betChoiceColor, setBetChoiceColor] = useState('');
  const [betChoiceResult, setBetChoiceResult] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {

    const userMobileNumber = getCookie('user');
        if (userMobileNumber) {
          setUserId(userMobileNumber);
        }
    // Update bet choices based on betType
    if (betType === 'Number') {
      setBetChoiceNumber(betData);
      setBetChoiceColor(''); // Clear other states
      setBetChoiceResult('');
    } else if (betType === 'Color') {
      setBetChoiceColor(betData);
      setBetChoiceNumber('');
      setBetChoiceResult('');
    } else if (betType === 'Size') {
      setBetChoiceResult(betData);
      setBetChoiceNumber('');
      setBetChoiceColor('');
    }
  }, [betType, betData]);

  useEffect(() => {
    // Ensure multiplier is valid
    if (multipliersamount) {
      setActiveMultiplier(multipliersamount);
    }
  }, [multipliersamount]);

  const handleIncrement = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + 1;
      updateActiveMultiplier(newQuantity);
      return newQuantity;
    });
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity > 1) {
        const newQuantity = prevQuantity - 1;
        updateActiveMultiplier(newQuantity);
        return newQuantity;
      }
      return prevQuantity;
    });
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value) || 1; // Default to 1 if parsing fails
    setQuantity(value);
    updateActiveMultiplier(value);
  };

  const handleMultiplierClick = (multiplier) => {
    const multiplierValue = parseInt(multiplier.slice(1)); // Extract the numeric part
    setQuantity(multiplierValue); // Update quantity based on multiplier
    setActiveMultiplier(multiplier); // Update active multiplier to reflect current state
  };

  const updateActiveMultiplier = (value) => {
    const multiplier = multipliers.find((mult) => parseInt(mult.slice(1)) === value);
    if (multiplier) {
      setActiveMultiplier(multiplier);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleConfirm = async () => {
    if (isChecked) {
      await handleSubmit();
    } else {
      toast.error('You must agree to the terms to proceed.');
    }
  };

  const getFirstColorFromGradient = (gradient) => {
    const match = gradient.match(/linear-gradient(?:\s*\(.*?,\s*(#[0-9a-fA-F]+|rgba?\([^)]+\)|hsl?\([^)]+\)))/i);
    if (match) {
      return match[1].trim();
    }
    return gradient;
  };

  const multiplierValue = parseInt(activeMultiplier.slice(1));
  const totalAmount = activeBalance * quantity; // Updated calculation
  const buttonColor = getFirstColorFromGradient(color);

  const handleSubmit = async () => {
    // Construct the payload
    const payload = {
      betid: betid,
      betamount: totalAmount,
      betchoicenumber: betChoiceNumber,
      betchoicecolor: betChoiceColor,
      betchoiceresult: betChoiceResult,
      userid: userId,
    };

    try {
      const response = await fetch('https://api.successfamily.live/api4/colourcreatebet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message);
        setStatus(data.status);
        toast.success(data.message);
        onClose(); // Close the modal upon successful submission
      } else {
        setMessage(data.error || 'Bet submission failed');
        setStatus(0);
        toast.error(data.error || 'Bet submission failed');
      }
    } catch (error) {
      console.error('Error submitting bet:', error);
      setMessage('An error occurred while submitting your bet.');
      setStatus(0);
      toast.error('An error occurred while submitting your bet.');
    }
  };
  const getCookie = (name) => {
    const nameEQ = name + '=';
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let c = cookiesArray[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  return (
    <div className={`${styles.modal} ${isOpen ? styles.modalOpen : ''}`} role="dialog" tabIndex="0">
      <div className={styles.modalContent}>
        
        {/* Modal Header */}
        <div className={styles.modalHeader} style={{ background: color }}>
          <div className={styles.modalTitle}>{gametime}</div>
          <div className={styles.modalSelect}>
            <span>Select</span>&nbsp;&nbsp;&nbsp;<span>{betData}</span>
          </div>
        </div>
        
        {/* Modal Body */}
        <div className={styles.modalBody}>
          
          {/* Balance Section */}
          <div className={styles.modalLine}>
            Balance 
            <div className={styles.modalLineList}>
              {[1, 10, 100, 1000].map((value) => (
                <div
                  key={value}
                  className={`${styles.modalLineItem} ${activeBalance === value ? styles.activeItem : ''}`}
                  onClick={() => setActiveBalance(value)}
                  style={{ background: activeBalance === value ? buttonColor : '' }} // Set background color of active item
                >
                  {value}
                </div>
              ))}
            </div>
          </div>
          
          {/* Quantity Section */}
          <div className={styles.modalLine}>
            Quantity 
            <div className={styles.modalLineBtn}>
              <button className={styles.modalBtn} onClick={handleDecrement} style={{ background: buttonColor }}>-</button>
              <input
                type="number"
                className={styles.modalInput}
                value={quantity}
                onChange={handleInputChange}
                min="1"
              />
              <button className={styles.modalBtn} onClick={handleIncrement} style={{ background: buttonColor }}>+</button>
            </div>
          </div>
          
          {/* Multipliers Section */}
          <div className={styles.modalLine}>
            <div className={styles.modalLineList}>
              {multipliers.map((multiplier) => {
                const multiplierValue = parseInt(multiplier.slice(1));
                const isActive = quantity === multiplierValue;
                return (
                  <div
                    key={multiplier}
                    className={`${styles.modalLineItem} ${isActive ? styles.activeItem : ''}`}
                    onClick={() => handleMultiplierClick(multiplier)}
                    style={{ background: isActive ? buttonColor : '' }} // Set background color of active item
                  >
                    {multiplier}
                  </div>
                );
              })}
            </div>
          </div>
          
          {/* Agreement Section */}
          <div className={styles.modalLine2}>
            <input
              type="checkbox"
              className={styles.modalCheckbox}
              id="agree"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="agree" className={styles.modalAgree}>I agree</label>
            <a href="#" className={styles.modalPreSale}>《Pre-sale rules》</a>
          </div>
        </div>
        
        {/* Modal Footer */}
        <div className={styles.modalFooter}>
          <button className={styles.modalCancel} onClick={onClose}>Cancel</button>
          <button className={styles.modalConfirm} onClick={handleConfirm} style={{ background: buttonColor }}>
            Total amount ₹{totalAmount}.00
          </button>
        </div>
      </div>
    </div>
  );
};

export default BetModal;
